




































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import Option from '@/types/option'
import { notification } from 'ant-design-vue'
import Column from '@/types/column'

@Component({
  components: {}
})

export default class CopyOptionBudgets extends Vue {
  @Prop() selectedOptionIds!:number[]
  @Prop() selectedOptions!:Option[]

  loading = { options: false, budgets: false }
  saving = false

  showModal = false
  searchTimer = 0
  options:{ id:number, name:string, external_ref:string }[] = []
  fromOptionId = null
  budgets:{ id:number }[] = []
  selectedBudgetIds:number[] = []

  columns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Omschrijving',
      key: 'description',
      scopedSlots: { customRender: 'description' }
    },
    {
      title: 'Aantal',
      key: 'qty',
      dataIndex: 'qty'
    },
    {
      title: 'Eenheid',
      key: 'unit',
      dataIndex: 'unit'
    },
    {
      title: 'Eenheidsprijs',
      key: 'unit_price',
      scopedSlots: { customRender: 'unit_price' }
    },
    {
      title: 'Totaalprijs',
      key: 'total_price',
      scopedSlots: { customRender: 'total_price' }
    }
  ]

  toggleModal (): void {
    this.showModal = !this.showModal
    this.fromOptionId = null
    this.budgets = []
  }

  getOptions (searchTerm:string) : void {
    clearTimeout(this.searchTimer)

    this.searchTimer = setTimeout(() => {
      this.loading.options = true

      axios.get(process.env.VUE_APP_API_URL + '/options?filter[search]=' + searchTerm + '&limit=250').then((r) => {
        this.options = r.data.data
      }).finally(() => {
        this.loading.options = false
      })
    }, 500)
  }

  getBudgets (optionId:number) : void {
    this.loading.budgets = true

    axios.get(process.env.VUE_APP_API_URL + '/optionBudgets?filter[option.id]=' + optionId + '&limit=250').then((r) => {
      this.budgets = r.data.data
      this.selectedBudgetIds = this.budgets.map((budget: { id: number }) => budget.id)
    }).finally(() => {
      this.loading.budgets = false
    })
  }

  onRowClick (rowData:{ id:number }) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          this.selectedBudgetIds.includes(rowData.id) ? this.selectedBudgetIds.splice(this.selectedBudgetIds.indexOf(rowData.id), 1) : this.selectedBudgetIds.push(rowData.id)
        }
      }
    }
  }

  onSelectChange (selectedRowKeys:number[]) : void {
    this.selectedBudgetIds = selectedRowKeys
  }

  copyBudgets (): void {
    const { fromOptionId, selectedBudgetIds } = this

    if (fromOptionId && this.selectedOptionIds.length && selectedBudgetIds.length) {
      this.saving = true
      axios.post(process.env.VUE_APP_API_URL + '/optionBudgets/copy', { fromOptionId: fromOptionId, toOptionIds: this.selectedOptionIds, budgetIds: selectedBudgetIds }).then(() => {
        this.$emit('budgetsCopied')
        this.toggleModal()
        notification.success({ message: 'Budgetten gekopieerd.', description: 'De budgetten zijn succesvol gekopieerd.', duration: 3 })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het kopiëren van budgetten.', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 0 })
      }).finally(() => {
        this.saving = false
      })
    }
  }

  get fromOption () : string|null {
    const option = this.options.find(opt => opt.id === this.fromOptionId)
    return option ? option.external_ref + ' - ' + option.name : null
  }
}
