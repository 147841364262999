













import { Vue, Component, Prop } from 'vue-property-decorator'
import Column from '@/types/column'

@Component({
  components: {}
})

export default class AdditionalBudgetOverview extends Vue {
  @Prop() budgets!:{ id:number, total_price:number }[]
  @Prop() salePriceWithVat!:number
  @Prop() vatRate!:number

  columns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Budget',
      key: 'budget',
      scopedSlots: { customRender: 'budget' }
    }
  ]

  get additionalBudgets (): { code: string, description: string, budget: number }[] {
    if (!this.budgets) return []

    const optionPriceWithoutVat = this.salePriceWithVat / (1 + this.vatRate / 100)
    const totalBudget = this.budgets.reduce((sum, item) => sum + item.total_price, 0)

    const additionalCosts = [
      { code: '600001', description: 'AK', budget: optionPriceWithoutVat * (7 / 107) },
      { code: '600008', description: 'Rendement & risico', budget: optionPriceWithoutVat * (2.5 / 102.5) },
      { code: '600004', description: 'Uitvoeringsrisico', budget: optionPriceWithoutVat * (0.5 / 100.5) },
      { code: '600003', description: 'CAR- & WA-verzekeringen', budget: optionPriceWithoutVat * (0.45 / 100.45) },
      { code: '600013', description: 'Servicekosten', budget: optionPriceWithoutVat * (0.7 / 100.7) }
    ]

    const totalAdditionalCosts = additionalCosts.reduce((sum, item) => sum + item.budget, 0)

    additionalCosts.push({
      code: '600035',
      description: 'Margeregel meerwerk',
      budget: optionPriceWithoutVat - (totalBudget + totalAdditionalCosts)
    })

    return additionalCosts
  }
}
