




























import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'

interface TotalsType {
  modular:number
  carcass:number
  completion:number
  extra:number
}

@Component({
  components: {}
})

export default class BudgetPushToAfas extends Vue {
  @Prop({ default: { modular: 0, carcass: 0, completion: 0, extra: 0 } }) totals!:TotalsType

  loading = false
  phase:number|null = null

  pushBudgetsToAfas () : void {
    this.loading = true
    axios.post(process.env.VUE_APP_API_URL + '/budgets/' + this.$route.params.projectId + '/push', { phase: this.phase }).then(() => {
      this.$emit('budgetsPushed')
      notification.success({ message: 'Budgetten ingelezen.', description: 'De budgetten zijn succesvol ingelezen in Afas.', duration: 3 })
    }).catch((e) => {
      if (e.response.status === 404) {
        notification.warning({ message: 'Geen budgetten ingelezen.', description: 'Er zijn op dit moment geen ' + this.phaseText + ' budgetten die kunnen worden ingelezen.', duration: 5 })
      } else {
        notification.error({ message: 'Budgetten NIET ingelezen.', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 0 })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  get phaseText () : string {
    switch (this.phase) {
      case 0:
        return 'modulaire'
      case 1:
        return 'ruwbouw'
      case 2:
        return 'afbouw'
      case 5:
        return 'extra'
      default:
        return '-'
    }
  }
}
