





















import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Column from '@/types/column'
import BudgetGuardCodeBudgets from '@/views/budget/components/BudgetGuardCodeBudgets.vue'
import { GuardCodeWithBudgets } from '@/types/guardCodeWithBudgets'

@Component({
  components: { BudgetGuardCodeBudgets }
})

export default class BudgetsGuardCodes extends Vue {
  @Prop() guardCodes!:GuardCodeWithBudgets[]
  @Prop({ default: false, type: Boolean }) inAfas!:boolean

  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  inAfasColumns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Ingelezen',
      key: 'in_afas',
      scopedSlots: { customRender: 'in_afas' }
    }
  ]

  notInAfasColumns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Modulair',
      key: 'not_in_afas.modular',
      scopedSlots: { customRender: 'not_in_afas.modular' }
    },
    {
      title: 'Ruwbouw',
      key: 'not_in_afas.carcass',
      scopedSlots: { customRender: 'not_in_afas.carcass' }
    },
    {
      title: 'Afbouw',
      key: 'not_in_afas.completion',
      scopedSlots: { customRender: 'not_in_afas.completion' }
    },
    {
      title: 'Extra',
      key: 'not_in_afas.extra',
      scopedSlots: { customRender: 'not_in_afas.extra' }
    },
    {
      title: 'Totaal',
      key: 'total',
      scopedSlots: { customRender: 'total' }
    }
  ]

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
