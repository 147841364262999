





















import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Column from '@/types/column'
import { BudgetBatch } from '@/types/budgetBatch'

@Component({
  components: {}
})

export default class BudgetBatchesTab extends Vue {
  @Prop() batches!:BudgetBatch[]

  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: 'Batch',
      key: 'reference',
      dataIndex: 'reference'
    },
    {
      title: 'Fase',
      key: 'phase',
      dataIndex: 'phase.text'
    },
    {
      title: 'Ingelezen budget',
      key: 'amount',
      scopedSlots: { customRender: 'amount' }
    },
    {
      title: 'Ingelezen door',
      key: 'pushed_by',
      dataIndex: 'pushed_by.full_name'
    },
    {
      title: 'Ingelezen op',
      key: 'pushed_at',
      scopedSlots: { customRender: 'pushed_at' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  navigateTo (routeName:string, id:number|undefined = undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
