





























































































































































import { Component, Watch, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'

@Component({
  components: {}
})

export default class Navigation extends Vue {
  selectedKeys:string[] = []
  openSubMenus:string[] = new SecureLS({ isCompression: false }).get('openSubMenus') || []

  @Watch('$route.name')
  onRouteChanged () : void {
    this.setActiveRoute()
  }

  mounted () : void {
    this.setActiveRoute()
  }

  setActiveRoute () : void {
    if (this.$route.name != null) {
      switch (this.$route.name) {
        case 'projectShow':
        case 'projectEdit':
          this.selectedKeys = ['projects']
          break
        case 'projectWebsiteEdit':
          this.selectedKeys = ['projectWebsites']
          break
        case 'buildingShow':
        case 'buildingEdit':
          this.selectedKeys = ['buildings']
          break
        case 'customerShow':
        case 'customerEdit':
          this.selectedKeys = ['customers']
          break
        case 'customerUserShow':
        case 'customerUserEdit':
          this.selectedKeys = ['customerUsers']
          break
        case 'tenantShow':
        case 'tenantEdit':
          this.selectedKeys = ['tenants']
          break
        case 'tenantUserShow':
        case 'tenantUserEdit':
          this.selectedKeys = ['tenantUsers']
          break
        case 'layoutShow':
        case 'layoutEdit':
          this.selectedKeys = ['layouts']
          break
        case 'elementShow':
        case 'elementEdit':
          this.selectedKeys = ['elements']
          break
        case 'subelementShow':
        case 'subelementEdit':
          this.selectedKeys = ['subelements']
          break
        case 'optionShow':
        case 'optionEdit':
          this.selectedKeys = ['options']
          break
        case 'buildingConfigShow':
          this.selectedKeys = ['buildingConfigs']
          break
        case 'orderShow':
          this.selectedKeys = ['orders']
          break
        case 'oldWoononOrderShow':
          this.selectedKeys = ['oldWoononOrders']
          break
        case 'companyShow':
        case 'companyEdit':
          this.selectedKeys = ['companies']
          break
        case 'companyUserShow':
        case 'companyUserEdit':
          this.selectedKeys = ['companyUsers']
          break
        case 'surveyShow':
        case 'surveyEdit':
          this.selectedKeys = ['surveys']
          break
        case 'surveyFormCreate':
        case 'surveyFormEdit':
          this.selectedKeys = ['surveyForms']
          break
        case 'messageCreate':
        case 'messageShow':
          this.selectedKeys = ['messages']
          break
        case 'issueEdit':
          this.selectedKeys = ['issues']
          break
        case 'objectionEdit':
          this.selectedKeys = ['objections']
          break
        case 'questionEdit':
          this.selectedKeys = ['questions']
          break
        case 'guardCodeShow':
        case 'guardCodeEdit':
          this.selectedKeys = ['guardCodes']
          break
        case 'budgetShow':
        case 'budgetBatchShow':
          this.selectedKeys = ['budgets']
          break
        case 'feedbackShow':
          this.selectedKeys = ['feedbacks']
          break
        default:
          this.selectedKeys = [this.$route.name]
          break
      }
    }
  }

  get canViewIssues () : boolean {
    return this.authUser.permissions.includes('issues.view') || this.authUser.permissions.includes('issues.edit')
  }

  get canViewQuestions () : boolean {
    return this.authUser.permissions.includes('questions.view') || this.authUser.permissions.includes('questions.edit')
  }

  get canViewObjections () : boolean {
    return this.authUser.permissions.includes('objections.view') || this.authUser.permissions.includes('objections.edit')
  }

  get canViewService () : boolean {
    return this.canViewIssues || this.canViewQuestions || this.canViewObjections
  }

  get canViewReports () : boolean {
    return this.authUser.permissions.includes('reports.view') || this.authUser.permissions.includes('reports.edit')
  }

  toggleSubMenus (openKeys:string[]) : void {
    this.openSubMenus = openKeys
    new SecureLS({ isCompression: false }).set('openSubMenus', openKeys)
  }

  logout () : void {
    this.$store.dispatch('logout')
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get screenWidth () : number {
    return screen.width
  }

  get environment (): string {
    switch (process.env.VUE_APP_MS_CALLBACK_DOMAIN) {
      case 'admin.woonon.nl': return 'production'
      case 's-admin.woonon.nl': return 'staging'
      default: return 'development'
    }
  }
}
