




















































































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Option from '@/types/option'
import TextEditor from '@/components/base/TextEditor.vue'

@Component({
  components: { TextEditor }
})

export default class OptionEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'options', show: 'optionShow', edit: 'optionEdit' },
    routeParamKey: 'optionId',
    functionNames: { setCurrent: 'setCurrentOption', save: 'saveOption' },
    getterNames: { current: 'currentOption', loading: 'loadingOption', errors: 'optionErrors' }
  }

  contentChanged (key:string, value:string) : void {
    this.$store.dispatch('optionTextContentChanged', { key: key, value: value })
  }

  get budgetWithoutMargin () : number {
    const optionPriceWithoutVat = this.currentItem.price / (1 + this.currentItem.vat_rate / 100)
    const totalBudgets = this.currentItem.option_budgets.reduce((sum, item) => sum + item.total_price, 0)

    const additionalCosts = [
      { code: '600001', description: 'AK', budget: optionPriceWithoutVat * (7 / 107) },
      { code: '600008', description: 'Rendement & risico', budget: optionPriceWithoutVat * (2.5 / 102.5) },
      { code: '600004', description: 'Uitvoeringsrisico', budget: optionPriceWithoutVat * (0.5 / 100.5) },
      { code: '600003', description: 'CAR- & WA-verzekeringen', budget: optionPriceWithoutVat * (0.45 / 100.45) },
      { code: '600013', description: 'Servicekosten', budget: optionPriceWithoutVat * (0.7 / 100.7) }
    ]

    const totalAdditionalCosts = additionalCosts.reduce((sum, item) => sum + item.budget, 0)

    return totalBudgets + totalAdditionalCosts
  }

  get budgetMargin () : number {
    const optionPriceWithoutVat = this.currentItem.price / (1 + this.currentItem.vat_rate / 100)
    return optionPriceWithoutVat - this.budgetWithoutMargin
  }

  get currentItem () : Option {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
