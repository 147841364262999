
























































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/nl'
import { notification } from 'ant-design-vue'
import Building from '@/types/building'
moment.locale('nl')

interface BulkDelivery {
  building_ids:number[]
  inspection_scheduled_at:moment.Moment|null
  inspection_scheduled_user_id:number|null
  delivery_scheduled_at:moment.Moment|null,
  delivery_scheduled_user_id:number|null
}

@Component({
  components: {}
})

export default class BuildingBulkDeliveryCreate extends Vue {
  @Prop() selectedIds!:number[]
  @Prop() selectedBuildings!:Building[]

  loading = { projects: false, buildings: false }
  saving = false

  delivery:BulkDelivery = {
    building_ids: [],
    inspection_scheduled_at: null,
    inspection_scheduled_user_id: null,
    delivery_scheduled_at: null,
    delivery_scheduled_user_id: null
  }

  employees = []

  mounted () : void {
    this.getEmployees()
    this.delivery.building_ids = this.selectedIds
  }

  getEmployees () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.display_name]=Medewerker&limit=2500').then((res) => {
      this.employees = res.data.data
    })
  }

  store () : void {
    this.saving = true
    axios.post(process.env.VUE_APP_API_URL + '/deliveries/bulk', this.delivery).then(() => {
      notification.success({ message: 'Voorschouw/oplevering(en) toegevoegd.', description: 'De voorschouw/oplevering(en) is/zijn succesvol toegevoegd.', duration: 3 })
      this.$emit('success')
    }).catch((e) => {
      console.log(e)
    }).finally(() => {
      this.saving = false
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get allBuildingsCanBeDelivered () : boolean {
    return this.selectedBuildings.every(building => building.delivery_can_be_scheduled)
  }

  get allRequiredFieldsFilled () : boolean {
    return !!(this.delivery.building_ids.length && this.delivery.inspection_scheduled_at && this.delivery.delivery_scheduled_at)
  }
}
