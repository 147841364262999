



































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import axios from 'axios'
import BudgetGuardCodes from '@/views/budget/components/BudgetGuardCodes.vue'
import { GuardCodeWithBudgets } from '@/types/guardCodeWithBudgets'

interface Batch {
  id:number
  reference:string
  phase:{ id:number, text:string }
  pushed_by:{ id:number, full_name:string }
  pushed_at:string
  amount:number
}

interface Project {
  id:number
  reference:string
  name:string
}

interface BudgetBatch {
  batch:Batch
  project:Project,
  guard_codes:GuardCodeWithBudgets[]
}

@Component({
  components: { BudgetGuardCodes }
})

export default class BudgetBatchShow extends Vue {
  settings = {
    routeNames: { list: 'budgets', show: 'budgetShow' },
    routeParamKey: 'projectId',
    permissionNames: { show: 'budgets.view', edit: 'budgets.edit', delete: 'budgets.delete' }
  }

  loading = false
  currentTab:string = new SecureLS({ isCompression: false }).get('budgetShowActiveTab') || 'not_in_afas'

  batch:BudgetBatch|null = null

  mounted () : void {
    this.getBudgetBatch()
  }

  getBudgetBatch () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/budgets/' + this.$route.params.projectId + '/batch/' + this.$route.params.budgetBatchId).then((r) => {
      this.batch = r.data
    }).catch(() => {
      console.log('Error')
    }).finally(() => {
      this.loading = false
    })
  }

  navigateTo (routeName:string, id:string|undefined = undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
