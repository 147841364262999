




















import { Vue, Component } from 'vue-property-decorator'
import moment from 'moment'
import 'moment/locale/nl'
import axios from 'axios'
import Customer from '@/types/customer'
moment.locale('nl')

@Component({
  components: {}
})

export default class BuildingBulkCustomerUpdater extends Vue {
  selectedCustomerId:number|null = null
  customers:Customer[] = []
  customerTimer = 0

  searchCustomer (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.customerTimer)

      this.customerTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/customers?filter[search]=' + val).then((res) => {
          this.customers = res.data.data
        })
      }, 500)
    }
  }

  customerChanged (val:number|undefined) : void {
    if (val === undefined) {
      this.$emit('customerChanged', null)
    } else {
      this.$emit('customerChanged', val)
    }
  }
}
