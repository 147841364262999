























import { Vue, Component, Prop } from 'vue-property-decorator'
import Feedback from '@/types/feedback'
import TextEditor from '@/components/base/TextEditor.vue'
import User from '@/types/user'
import SecureLS from 'secure-ls'

@Component({
  components: { TextEditor }
})

export default class FeedbackRequirements extends Vue {
  @Prop() feedback!: Feedback
  @Prop({ default: false, type: Boolean, required: false }) canEdit!: boolean
  @Prop({ default: false, type: Boolean, required: false }) canView!: boolean

  ls = new SecureLS({ isCompression: false })
  showEditor = false
  loading = false

  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  save () : void {
    this.$store.dispatch('saveFeedback', this.feedback)
    this.showEditor = false
  }

  get authUser () : User {
    return this.ls.get('authUser')
  }
}
