



































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Column from '@/types/column'
import { Budget } from '@/types/guardCodeWithBudgets'

@Component({
  components: {}
})

export default class BudgetGuardCodeBudgets extends Vue {
  @Prop() budgets!:Budget[]
  @Prop() filters!:{ [key:string]: { text:string, value:string|number }[] }

  columns:Column[] = [
    {
      title: 'Bnr.',
      key: 'building',
      scopedSlots: { customRender: 'building' },
      filters: [],
      onFilter: (value:string|number|boolean, record:Budget) : boolean => record.building_id === value
    },
    {
      title: 'Order',
      key: 'order',
      scopedSlots: { customRender: 'order' },
      filters: [],
      onFilter: (value:string|number|boolean, record:Budget) : boolean => record.order_id === value
    },
    {
      title: 'Fase',
      key: 'phase',
      scopedSlots: { customRender: 'phase' },
      filters: [
        { text: 'Modulair', value: 0 },
        { text: 'Ruwbouw', value: 1 },
        { text: 'Afbouw', value: 2 },
        { text: 'Extra', value: 5 }
      ],
      onFilter: (value:string|number|boolean, record:Budget) : boolean => record.phase === value
    },
    {
      title: 'Aantal',
      key: 'qty',
      dataIndex: 'qty'
    },
    {
      title: 'Layout',
      key: 'layout',
      scopedSlots: { customRender: 'layout' },
      filters: [],
      onFilter: (value:string|number|boolean, record:Budget) : boolean => record.layout_id === value
    },
    {
      title: 'Optie',
      key: 'option',
      scopedSlots: { customRender: 'option' },
      filters: [],
      onFilter: (value:string|number|boolean, record:Budget) : boolean => record.option_id === value
    },
    {
      title: 'Extra optie',
      key: 'extra_option',
      scopedSlots: { customRender: 'extra_option' },
      filters: [],
      onFilter: (value:string|number|boolean, record:Budget) : boolean => record.extra_option_id === value
    },
    {
      title: 'Budget omschrijving',
      key: 'budget_description',
      dataIndex: 'budget_description'
    },
    {
      title: 'Budget aantal',
      key: 'budget_qty',
      scopedSlots: { customRender: 'budget_qty' }
    },
    {
      title: 'Eenheidsprijs',
      key: 'budget_unit_price',
      scopedSlots: { customRender: 'budget_unit_price' }
    },
    {
      title: 'Totaal',
      key: 'budget_total_price',
      scopedSlots: { customRender: 'budget_total_price' }
    }
  ]

  mounted () : void {
    this.applyFilters()
  }

  applyFilters () : void {
    this.columns[0].filters = this.filters.building || []
    this.columns[1].filters = this.filters.order || []
    this.columns[4].filters = this.filters.layout || []
    this.columns[5].filters = this.filters.option || []
    this.columns[6].filters = this.filters.extra_option || []
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
