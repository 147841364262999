






























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import axios from 'axios'
import BudgetGuardCodes from '@/views/budget/components/BudgetGuardCodes.vue'
import { GuardCodeWithBudgets } from '@/types/guardCodeWithBudgets'
import BudgetBatchesTab from '@/views/budget/tabs/BudgetBatchesTab.vue'
import BudgetPushToAfas from '@/views/budget/components/BudgetPushToAfas.vue'

interface Project {
  id:number
  reference:string
  name:string
}

interface ProjectBudget {
  project:Project,
  guard_codes:GuardCodeWithBudgets[],
  batches:[],
  totals:{ modular:number, carcass:number, completion:number, extra:number }
}

@Component({
  components: { BudgetPushToAfas, BudgetBatchesTab, BudgetGuardCodes }
})

export default class BudgetShow extends Vue {
  settings = {
    routeNames: { list: 'budgets', show: 'budgetShow' },
    routeParamKey: 'projectId',
    permissionNames: { show: 'budgets.view', edit: 'budgets.edit', delete: 'budgets.delete' }
  }

  loading = false
  currentTab:string = new SecureLS({ isCompression: false }).get('budgetShowActiveTab') || 'not_in_afas'

  projectBudgets:ProjectBudget|null = null

  mounted () : void {
    this.getBudgets()
  }

  getBudgets () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/budgets/' + this.$route.params.projectId).then((r) => {
      this.projectBudgets = r.data
    }).catch(() => {
      console.log('Error')
    }).finally(() => {
      this.loading = false
    })
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('budgetShowActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined = undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
