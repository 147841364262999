











import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import 'moment/locale/nl'
import Option from '@/types/option'
import CopyOptionBudgets from '@/views/option/components/CopyOptionBudgets.vue'
moment.locale('nl')

@Component({
  components: { CopyOptionBudgets }
})

export default class OptionBulkUpdater extends Vue {
  @Prop() selectedOptionIds!:number[]
  @Prop() selectedOptions!:Option[]
}
