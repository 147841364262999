import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import BudgetModel from '@/models/BudgetModel'
import { notification } from 'ant-design-vue'

Vue.use(Vuex)

export const budgetStore: Module<any, any> = {
  state: {
    budgetList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setBudgetList (state, { budgetList }) {
      state.budgetList = budgetList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    }
  },
  actions: {
    getBudgetList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let budgetModel = new BudgetModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            budgetModel = budgetModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        budgetModel = budgetModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        budgetModel = budgetModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        budgetModel = budgetModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      budgetModel.get().then((budgetList) => {
        commit('setBudgetList', { budgetList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de budgetten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    }
  },
  getters: {
    budgetList: state => state.budgetList,
    loadingBudget: state => state.loading
  }
}
