














































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Column from '@/types/column'
import Filter from '@/types/filter'
import _ from 'lodash'
import SecureLS from 'secure-ls'
import axios from 'axios'
import TagCloud from '@/components/base/TagCloud.vue'

export type FilterPreset = {
  name: string
  filters: Record<string, (string|number|boolean)[]>
}

@Component({
  components: { TagCloud }
})

export default class ActiveFilters extends Vue {
  @Prop() activeFilters!:Record<string, (string|number|boolean)[]>
  @Prop() columns!:Column[]
  @Prop() view!:string
  @Prop({ type: String, required: false }) model!: string | null

  @Watch('presetModalVisible')
  onPresetModalVisibleChanged (newValue: boolean) : void {
    if (newValue) {
      this.$nextTick(() => {
        const input = this.$refs.presetInput as HTMLInputElement
        input.focus()
      })
    }
  }

  filters:Record<string, (string|number|boolean)[]> = {}
  newPresetName = ''
  activePreset: FilterPreset | null = null
  presets:FilterPreset[] = []

  _ = _
  ls = new SecureLS({ isCompression: false })

  mounted () : void {
    this.presets = this.getPresets()
    this.activePreset = this.getActivePreset()
    this.loadPresets()
  }

  activeKeyChanged (key: string) : void {
    this.ls.set(this.view + 'filters', key)
  }

  get collapseKey () : string | null {
    const stored = this.ls.get(this.view + 'filters') ?? null
    return _.values(stored)[0] ?? null
  }

  getPresets () : FilterPreset[] {
    return this.ls.get(this.storedPresetsKey) !== '' ? this.ls.get(this.storedPresetsKey) : []
  }

  getActivePreset () : FilterPreset | null {
    return this.ls.get(this.activePresetKey) ?? null
  }

  onTagsChanged (tags: number[]) : void {
    this.activeFilters.tags = tags
    this.$emit('update', this.activeFilters)
  }

  get storedPresetsKey () : string {
    return this.view + 'DefaultFilters'
  }

  get activePresetKey () : string {
    return this.view + 'ActivePreset'
  }

  get allActiveFilters () : Record<string, (string|number|boolean)[]> {
    for (const key in this.activeFilters) {
      if (this.activeFilters[key].length === 0) {
        delete this.activeFilters[key]
      }
    }

    if (!_.isEqual(Object.entries(this.activeFilters), Object.entries(this.activePreset?.filters ?? {}))) {
      this.activePreset = null
    }

    return this.activeFilters
  }

  get activeFiltersExcludingTags () : Record<string, (string|number|boolean)[]> {
    return _.omit(this.allActiveFilters, 'tags')
  }

  get activeTags () : number[] {
    return (this.activeFilters.tags ?? []) as number[]
  }

  getFilterName (index:string, value:string|number|boolean) : string {
    let filteredValue:string|number = ''
    this.columns.forEach((column:Column) => {
      if (column.filters && column.key === index) {
        column.filters.forEach((filterItem:Filter) => {
          if (filterItem.value === value) {
            filteredValue = filterItem.text
          }
        })
      }
    })

    return filteredValue
  }

  presetModalVisible = false

  addPreset () : void {
    if (this.presetExists) {
      this.$message.error('Voorinstelling bestaat al.')
      return
    }
    if (this.newPresetName.length === 0) {
      this.$message.error('Vul een naam in voor de voorinstelling.')
      return
    }

    const preset:FilterPreset = {
      name: this.newPresetName,
      filters: _.cloneDeep(this.allActiveFilters)
    }

    this.presets.push(preset)

    this.newPresetName = ''
    this.presetModalVisible = false
    this.activePreset = preset

    this.storeActivePresetSetting()
    this.storePresetSettings()
    this.$message.success('Voorinstelling geladen.')
  }

  presetExists = false
  checkIfPresetExists (name:string) : void {
    this.presetExists = this.presets.map((preset:FilterPreset) => preset.name).includes(name)
  }

  cancelPreset () : void {
    this.newPresetName = ''
    this.presetModalVisible = false
  }

  loadPresets () : void {
    const storageKey = this.view + 'Presets'
    const rawData = this.ls.get(storageKey)
    if (rawData) {
      this.presets = JSON.parse(rawData)
    }
  }

  deletePreset (index:number) : void {
    if (this.presets[index].name === this.activePreset?.name) {
      this.activePreset = null
    }

    this.presets.splice(index, 1)

    this.storeActivePresetSetting()
    this.storePresetSettings()
    this.$message.success('Voorinstelling verwijderd.')
  }

  applyPreset (preset:FilterPreset) : void {
    this.activePreset = preset

    this.storeActivePresetSetting()
    this.storePresetSettings()

    this.activeFilters.tags = preset.filters.tags ?? []

    this.$message.success('Voorinstelling geladen.')
    this.$emit('update', _.cloneDeep(preset.filters))
  }

  storePresetSettings () : void {
    this.ls.set(this.storedPresetsKey, this.presets)
    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', { settingName: this.storedPresetsKey, settingValue: this.presets })
  }

  storeActivePresetSetting () : void {
    this.ls.set(this.activePresetKey, this.activePreset)
    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', { settingName: this.activePresetKey, settingValue: this.activePreset })
  }

  removeFilter (key:string, value:string|number|boolean) : void {
    this.allActiveFilters[key].splice(this.allActiveFilters[key].indexOf(value as never), 1)
    this.$emit('update', this.allActiveFilters)
  }

  removeAllFilters (): void {
    this.$emit('update', {})
  }

  getPrettyColumnName (columnKey: string): string {
    const column = this.columns.find((column: Column) => column.key === columnKey)
    return column?.filterLabel ?? column?.title ?? columnKey
  }

  get activePresetName () : string {
    return this.activePreset?.name ?? ''
  }

  get hasPresets (): boolean {
    return this.presets.length > 0
  }

  get hasActiveFilters (): boolean {
    return !_.isEmpty(this.allActiveFilters)
  }
}
